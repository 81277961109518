.entities-table-with-editable-column__card-header {
    background-color: #f5f5f5;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.12);
}

.entities-table-with-editable-column__card-header-content {
    display: flex;
    justify-content: space-between;
}

.entities-table-with-editable-column .entities-table-with-editable-column__card-content {
    padding: 3rem 4rem;
}

.entities-table-with-editable-column__search-field {
    background-color: #fff;
}

.entities-table-with-editable-column__card-header-btn-block {
    display: flex;
}

.entities-table-with-editable-column__card-header-btn-block button + button {
    margin-left: 1rem;
}

.entities-table-with-editable-column__card-header-btn-block  .entities-table-with-editable-column__fill-all-values-btn {
    margin-right: 2rem;
}

.entities-table-with-editable-column__card-header-btn-block button{
    font-size: 1.4rem;
}

.entities-table-with-editable-column__card-table .entities-table-with-editable-column__card-table-header .entities-table-with-editable-column__card-table-cell {
    font-size: 1.4rem;
}

.entities-table-with-editable-column__card-table-row--highlighted .entities-table-with-editable-column__card-table-cell {
    font-weight: bold;
}

.entities-table-with-editable-column__card-table .entities-table-with-editable-column__card-table-cell {
    font-size: 1.4rem;
    padding-left: 0;
}

.entities-table-with-editable-column__card-table .entities-table-with-editable-column__card-table-cell:last-child {
    text-align: right;
}

.entities-table-with-editable-column__card-table .entities-table-with-editable-column__card-table-cell-input {
    width: 11rem;
    position: relative;
    margin: 0.5rem 0;
}

.entities-table-with-editable-column__card-table .entities-table-with-editable-column__card-table-cell-input input {
    font-size: 1.4rem;
    padding: 1rem 1.4rem;
}

.entities-table-with-editable-column__card-table .entities-table-with-editable-column__card-table-cell-input p {
    font-size: 1.1rem;
    margin: 0 0 0 0.1rem;
    position: absolute;
    top: 100%;
    left: 0;
}

.entities-table-with-editable-column .MuiTooltip-popper .entities-table-with-editable-column__card-table-row-error-tooltip.MuiTooltip-tooltip{
    max-width: 80rem;
    margin: 3rem 0 0 0;
}

.entities-table-with-editable-column .MuiTooltip-popper .entities-table-with-editable-column__card-table-row-error-tooltip-content {
    font-size: 1.3rem;
    line-height: 1.7rem;
    padding: 1rem 0;
}

.entities-table-with-editable-column__pagination {
    padding: .4rem 0;
}