.parts-and-materials-reserve-for-assembly-sheet{
    position: relative;
}

.parts-and-materials-reserve-for-assembly-sheet__title{
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    text-align: center;
}

button.parts-and-materials-reserve-for-assembly-sheet__send-to-production-button,
.parts-and-materials-reserve-for-assembly-sheet__sent-to-production-label {
    font-size: 1.4rem;
}

.parts-and-materials-reserve-for-assembly-sheet__send-to-production-button-wrap,
.parts-and-materials-reserve-for-assembly-sheet__sent-to-production-label,
button.parts-and-materials-reserve-for-assembly-sheet__send-to-production-button--can-send-to-production{
    position: absolute;
    top: 0;
    right: 0;
}

.parts-and-materials-reserve-for-assembly-sheet__send-to-production-button-tooltip-popper > .parts-and-materials-reserve-for-assembly-sheet__send-to-production-button-tooltip {
    font-size: 1.2rem;
}

.parts-and-materials-reserve-for-assembly-sheet__info-alert{
    margin-bottom: 2rem;
}