.inappropriate-sheet-review-content .simple-help-alert__chip {
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
}

.inappropriate-sheet-review-content__sheet_summary .simple-info-row__label {
    font-weight: bold;
}

.inappropriate-sheet-review-content__actions {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 0;
}

.inappropriate-sheet-review-content__action-button {
    text-align: right;
}

.inappropriate-sheet-review-content__action-button:last-child {
    margin-left: 1rem;
}

.inappropriate-sheet-review-content__action-button button {
    font-size: 1.4rem;
}

.inappropriate-sheet-review-content__confirm-dialog-summary .simple-summary__primary-content-wrapper {
    font-size: 1.6rem;
    text-transform: none;
    margin-bottom: 0;
}

.inappropriate-sheet-review-content__confirm-dialog-summary {
    display: flex;
    justify-content: space-between;
}

.inappropriate-sheet-review-content__confirm-dialog-summary .simple-summary{
    width: 48%;
}