.worker-tasks-table-columns-settings {
  max-width: 80rem;
}


.worker-tasks-table-columns-settings .worker-tasks-table-columns-settings__visibility-checkbox {
  padding: 0;
  margin-right: .7rem;
}

.worker-tasks-table-columns-settings .worker-tasks-table-columns-settings__visibility-checkbox svg {
  width: 2.4rem;
  height: 2.4rem;
}


.worker-tasks-table-columns-settings .simple-table-with-draggable-rows__draggable-row--is-visible{
  background: #E8E8E8;
}


.worker-tasks-table-columns-settings .simple-table-with-draggable-rows__column-0 {
  width: 25rem;
  max-width: calc(50% - 6.4rem);
}

.worker-tasks-table-columns-settings .simple-table-with-draggable-rows__column-1 {
  justify-content: center;
  width: 17.2rem;
  max-width: calc(50% - 6.4rem);
}
