.worker-navigation-panel{
    height: 100%;
    overflow: hidden;
}

.worker-navigation-panel .worker-navigation-panel__control-button{
    height: 100%;
    width: 13rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s ease-in-out;
}

.worker-navigation-panel__go-back-button{
    float: left;
}

.worker-navigation-panel .worker-navigation-panel__go-back-button--invisible{
    width: 0;
    opacity: 0;
    margin-right: 0;
}

.worker-navigation-panel .worker-navigation-panel__control-button svg.worker-navigation-panel__control-button-icon {
    font-size: 9rem;
}

.worker-navigation-panel__titles{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.worker-navigation-panel__title{
    font-size: 2rem;
    transition: all 0.5s ease-in-out;
    white-space: nowrap;
    overflow: hidden;
}

.worker-navigation-panel__title--active{
    font-size: 3.3rem;
    margin-bottom: 0.5rem;
}

.worker-navigation-panel__title--invisible{
    font-size: 0;
}
