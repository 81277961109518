.app-type-top-bar{
    font-weight: bold;
}

.app-type-top-bar--large {
    height: 13rem;
    justify-content: center;
}

.app-type-top-bar--large .app-type-top-bar__app-title {
    font-size: 3.3rem;
}

.app-type-top-bar__app-title{
    flex-grow: 1;
    text-transform: uppercase;
    font-size: 1.6rem;
}

.app-type-top-bar .app-type-top-bar__control-panel .MuiIconButton-root svg {
    font-size: 2.4rem;
    color: white;
}

.app-type-top-bar--large .app-type-top-bar__control-panel .MuiIconButton-root {
    margin: 0 1.55rem;
}

.app-type-top-bar--large .app-type-top-bar__control-panel .MuiIconButton-root svg {
    font-size: 7.2rem ;
}

.app-type-top-bar__toolbar {
    display: flex;
    justify-content: space-between;
}

.app-type-top-bar__control-panel {
    display: flex;
}