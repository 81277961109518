.sheets-to-start{
    position: relative;
}

.sheets-to-start__selected-type-toggle-btn-group {
    position: absolute;
    right: 0;
    top: 0;
}

.sheets-to-start__selected-type-toggle-btn-group--all-option-selected{
    top: 1.7rem;
}

.sheets-to-start__selected-type-toggle-btn-group > button {
    width: 8rem;
    color: #333;
    padding: 0.7rem;
}

.sheets-to-start__selected-type-toggle-btn-group > button {
    font-size: 1.3rem;
}

.sheets-to-start__selected-type-toggle-btn-group > button.sheets-to-start__selected-type-btn--selected {
    background-color: #2196f3;
    color: white;
}

.sheets-to-start__selected-type-toggle-btn-group > button.sheets-to-start__selected-type-btn--selected:hover {
    background-color: rgba(33, 150, 243, 0.8);
}

