.cyber-assistant-app{
    height: 100%;
}

.app-container {
    margin: 12rem;
}

@media screen and (max-width: 1280px){
    .app-container {
        margin: 7rem 1.5rem 1.5rem;
    }
}