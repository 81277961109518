.MuiToggleButtonGroup-root.text-field-toggle-button-group {
  display: flex;
}

.MuiToggleButtonGroup-root.text-field-toggle-button-group .text-field-toggle-button-group__toggle-button {
  flex: 1 0 auto;
  width: calc(50% - 0.25rem);
  border: 0;
  padding: .5rem 1rem;
  font-size: 1.4rem;
  line-height: .9;
  text-transform: none;
}

.MuiToggleButtonGroup-root.text-field-toggle-button-group button.text-field-toggle-button-group__toggle-button {
  border-radius: .3rem;
}

.MuiToggleButtonGroup-root.text-field-toggle-button-group .text-field-toggle-button-group__toggle-button:not(:first-of-type) {
  margin-left: 0.5rem;
}