.sheet-operations-features-form .sheet-operations-features-form__btn {
    font-size: 1.2rem;
}

.sheet-operations-features-form__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
}

.sheet-operations-features-form__text-field-wrapper {
    display: block;
}

.sheet-operations-features-form .text-form-field__text-field {
    margin-bottom: 0;
}

.sheet-operations-features-form__no-data-text {
    color: rgba(0,0,0,0.5);
    font-size: 1.5rem;
    margin: 1rem 0;
}

.sheet-operations-features-form__help-alert .simple-help-alert__chip {
    margin: 1rem 0 2rem;
}