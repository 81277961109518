.filters{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.filters Button,
.filters__chip span{
    font-size: 1.2rem;
}

.filters__filters-selecting-modal .filters__filters-selecting-modal-paper,
.filters__filters-selecting-modal .filters__filters-components-wrapper{
    overflow-y: visible;
}

.filters__filters-selecting-modal .filters__filters-components-wrapper{
     padding: 0.8rem 3rem;
 }

.filters__filters-selecting-modal .filters__create-filter-control {
    overflow-y: visible;
    margin-bottom: 1.4rem;
}

.filters__filters-selecting-modal .filters__title{
    padding: 2.4rem;
    margin-bottom: 1.8rem;
}

.filters__title div{
    font-size: 2rem;
    border-bottom: 0.1rem solid grey;
}

.filters__filters-btn-block button{
    font-size: 1.3rem;
    margin: 0;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
}

.filters__chip {
    margin: 0 0.4rem;
}

.filters__filter-component{
    display: flex;
    margin-bottom: 4rem;
    align-items: flex-end;
}

.filters__filter-component:last-child{
    margin-bottom: 1.5rem;
}

.filters__filter-component-row {
    width: 100%;
}

.filters__filter-component .filters__filter-component-delete-button{
    padding: 1rem;
}
