.tasks-owner-choose-screen .simple-item-choose-panel {
    padding-top: 0;
}

.tasks-owner-choose-screen__tasks-owner-card-main-title,
.tasks-owner-choose-screen__tasks-owner-card-secondary-title{
    overflow: hidden;
    text-overflow: ellipsis;
}

.tasks-owner-choose-screen__tasks-owner-card-main-title{
    font-size: 2.5rem;
    margin-bottom: 4rem;
    font-weight: bold;
}

.tasks-owner-choose-screen__tasks-owner-card-secondary-title-label{
    margin-right: 1rem;
    font-size: 1.5rem;
}

.tasks-owner-choose-screen__tasks-owner-card-secondary-title-value{
    font-weight: bold;
    font-size: 1.7rem;
}

.tasks-owner-choose-screen__search-text-field-panel {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.tasks-owner-choose-screen__search-text-field-panel .tasks-owner-choose-screen__search-text-field-input-wrap {
    font-size: 1.6rem;
    width: 40rem;
}

.tasks-owner-choose-screen__search-text-field-panel input {
    padding: 0.9rem 1rem;
    font-size: 1.4rem;
    background-color: #fff;
}

.tasks-owner-choose-screen__search-text-field-panel label {
    font-size: 1.6rem;
    z-index: 1;
}

