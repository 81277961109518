.split-entity-batch-dialog__split-reason-label {
    margin-bottom: 0.5rem;
}

.split-entity-batch-dialog__split-reason-textarea > div{
    font-size: 1.6rem;
    padding: 1.2rem;
}

#split-entity-batch-dialog__split-reason-textarea{
    line-height: 2rem;
}

.split-entity-batch-dialog__entities-amount-to-split-input {
    display: block;
}

.split-entity-batch-dialog__initial-batch-size-alert {
    margin-bottom: 1.9rem;
    font-size: 1.4rem;
}

.split-entity-batch-dialog__split-reason-textarea p {
    font-size: 1.1rem;
    margin: 0.3rem 0 0 0;
}

.split-entity-batch-dialog__initial-batch-size-value {
    margin-left: 1rem;
    display: inline-flex;
    align-items: center;
}

.split-entity-batch-dialog__progress-of-parent-batch-before-splitting-value{
    margin-left: 1rem;
}

.split-entity-batch-dialog__initial-batch-size-value-arrow-icon{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.split-entity-batch-dialog__initial-batch-size-value-arrow-icon{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}