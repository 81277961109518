.admin-app .admin-app__top-bar {
    z-index: 1300;
    height: 6.4rem;
}

.admin-app .admin-app__side-bar> div {
    margin-top: 6.4rem;
    width: 25rem;
}

.admin-app__content {
    padding-top: 10.4rem;
    padding-left: 29rem;
    padding-right: 4rem;
}