.sheet-operation-feature-review-screen {
    margin: 3rem auto;
}

.sheet-operation-feature-review-screen .ReactTable {
    box-shadow: none;
}

.sheet-operation-feature-review-screen__feature-scope-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.sheet-operation-feature-review-screen__text-field-wrapper {
    display: block;
}

.sheet-operation-feature-review-screen__text-field.text-form-field__text-field  {
    margin-bottom: 0;
}

.sheet-operation-feature-review-screen .sheet-operation-feature-review-screen__card .card-with-custom-header__card-content {
    padding: 2.9rem 1rem 1.6rem 1rem;
}

.sheet-operation-feature-review-screen__card-title {
    font-size: 1.6rem;
    margin: 0 8rem 0 2rem;
}

.sheet-operation-feature-review-screen .sheet-operation-feature-review-screen__return-to-features-screen-button {
    color: white;
    font-size: 1.3rem;
    padding: 0.8rem 1rem;
}

.sheet-operation-feature-review-screen__section {
    margin-bottom: 4rem;
}

.sheet-operation-feature-review-screen .card-with-custom-header__header-wrap {
    height: 6.8rem;
    display: flex;
    align-items: center;
}

.sheet-operation-feature-review-screen__return-to-features-screen-button-icon {
    font-size: 1.6rem;
}

.sheet-operation-feature-review-screen .sheet-operation-feature-review-screen__subtitle {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    line-height: 1;
    margin-bottom: 0.5rem;
}

.sheet-operation-feature-review-screen .sheet-operation-feature-review-screen__button {
    font-size: 1.2rem;
}

.sheet-operation-feature-review-screen .sheet-operation-feature-review-screen__card-actions {
    padding: 0;
    display: flex;
    justify-content: flex-end;
}

.sheet-operation-feature-review-screen .sheet-operation-feature-review-screen__tooltip {
    display: flex;
    padding-left: 1rem;
}

.sheet-operation-feature-review-screen .sheet-operation-feature-review-screen__info-icon {
    width: 1.7rem;
    height: 1.7rem;
}

.sheet-operation-feature-review-screen__switch-control {
    margin: 0;
    font-size: 1.3rem;
}