.tasks {
    padding: 3rem 1rem 0 1rem;
}

.tasks__additional-table-filters-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.tasks__additional-table-filters.checkbox-filters-panel {
    margin-bottom: 0;
}

button.tasks__scan-barcode_button {
    font-size: 1.2rem;
}
