html{
    height: 100%;
    font-size: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

body{
    height: 100%;
    line-height: 1.42857143;
    color: #333;
    background-color: #f9f9f9;
    margin: 0;
    font-size: 1.3rem;
    font-family: 'Roboto Condensed', sans-serif;
}

#root{
    height: 100%
}

/* roboto-condensed-regular - cyrillic-ext_latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/roboto-condensed-v13-cyrillic-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
    url('./fonts/roboto-condensed-v13-cyrillic-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/roboto-condensed-v13-cyrillic-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/roboto-condensed-v13-cyrillic-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./fonts/roboto-condensed-v13-cyrillic-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/roboto-condensed-v13-cyrillic-ext_latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
