div.user-role-chip {
    margin: 0.2rem;
    height: 2.5rem;
}

.user-role-chip span {
    font-size: 1.2rem;
}

.user-role-chip div.user-role-chip__avatar {
    font-size: 1.2rem;
    color: white;
    height: 2rem;
    width: 2rem;
    margin-left: 0.3rem;
}

.user-role-chip .user-role-chip__delete-icon {
    margin-right: 0.2rem;
}