button.table-menu__dropdown-btn{
    font-size: 1.2rem;
    position: relative;
}

.table-dropdown-menu {
    position: absolute;
    right: 0;
    z-index: 20;
}

.table-columns-visibility-option-sub-menu {
    max-height: 24rem;
    overflow-y: auto;
}