.default-sheet-parts-and-materials-consuming-content{
    position: relative;
}

.default-sheet-parts-and-materials-consuming-content__consume-button{
    position: absolute;
    top: 0;
    right: 0;
}

.default-sheet-parts-and-materials-consuming-content__consume-button button{
    font-size: 1.4rem;
}

.default-sheet-parts-and-materials-consuming-content__title{
    font-size: 2.5rem;
    margin-bottom: 5rem;
    text-transform: uppercase;
    text-align: center;
}

.default-sheet-parts-and-materials-consuming-content__detailed-consuming-cards-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header{
    background-color: #f5f5f5;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.12);
}

.default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header-entity-info-row-wrap{
    margin-bottom: 0.5rem;
}

.default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header .default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header-info-row{
    font-size: 1.6rem;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
}

.default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header .default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header-info-row:last-child{
    margin-bottom: 0;
}

.default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header-info-row .default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header-info-row-label{
    margin-right: 1rem;
}

.default-sheet-parts-and-materials-consuming-content__detailed-consuming-card{
    margin-bottom: 4rem;
    width: 70%;
}

.default-sheet-parts-and-materials-consuming-content > .default-sheet-parts-and-materials-consuming-content__detailed-consuming-cards-wrap .default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-content{
    padding: 3rem 4rem;
}

.default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-table-title{
    font-size: 1.4rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.default-sheet-parts-and-materials-consuming-content .default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-table-cell{
    font-size: 1.4rem;
    padding-left: 0;
}

.default-sheet-parts-and-materials-consuming-content__no-data-to-consume-info{
    font-size: 1.4rem;
    padding-top: 1.8rem;
    font-style: italic;
}