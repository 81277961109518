.master-workspace.app-container {
    margin-top: 4rem;
}

.master-workspace__control-panel-wrapper {
    display: flex;
    align-items: flex-end;
}

.master-workspace__departments-autocomplete {
    flex-grow: 1;
    margin-right: 2rem;
}

.master-workspace__departments-select-title,
.master-workspace__departments-select{
    text-transform: uppercase;
}

.master-workspace__view-mode-toggle-btn-group button {
    width: 15rem;
    color: #333;
    padding: 0.7rem;
}

.master-workspace__view-mode-toggle-btn-group button {
    font-size: 1.3rem;
}

.master-workspace .master-workspace__view-mode-toggle-btn-group .master-workspace__view-mode-toggle-btn--disabled {
    color: rgba(0, 0, 0, 0.3);
}

.master-workspace .master-workspace__view-mode-toggle-btn-group .master-workspace__view-mode-toggle-btn--selected {
    background-color: #2196f3;
    color: white;
}

.master-workspace .master-workspace__view-mode-toggle-btn-group .master-workspace__view-mode-toggle-btn--selected:hover {
    background-color: rgba(33, 150, 243, 0.8);
}