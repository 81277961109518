.worker-app{
    position: relative;
    height: 100%;
}

.worker-app__eq-class-in-dep-tasks-content-wrap {
    padding-top: 15rem;
}

.worker-app__tasks-content-wrap{
    padding-top: 6rem;
}

.worker-app__eq-class-in-dep-tasks-main-app-bar > div,
.worker-app-type-top-bar--tasks > div{
    padding-left: 0;
}