.sheet-operation-features.card-with-custom-header {
    margin-top: 3rem;
}

.sheet-operation-features .ReactTable {
    box-shadow: none;
}

.sheet-operation-features__table-wrapper {
    padding: 0 2rem 2rem 2rem;
}

.sheet-operation-features .sheet-operation-features__create-new {
    font-size: 1.3rem;
    line-height: 1.25;
    color: white;
    padding: 1.1rem 1rem;
}