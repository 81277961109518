.consume-entities {
    padding: 0 2rem;
}

.consume-entities__title{
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    font-size: 2rem;
}

.consume-entities__table {
    margin: 2rem 0;
}