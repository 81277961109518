.worker-tasks-title-component {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.worker-tasks-title-component .worker-tasks-title-component__control-button {
    height: 100%;
    width: 5rem;
    cursor: pointer;
    user-select: none;
}

.worker-tasks-title-component svg.worker-tasks-title-component__control-button-icon {
    font-size: 2.4rem;
}


.worker-tasks-title-component__title-wrap {
    display: flex;
    align-items: center;
    height: 100%;
}

.worker-tasks-title-component__title {
    font-size: 1.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
}
