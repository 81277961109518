.scan-barcode-dialog__title-wrapper {
  display: flex;
  justify-content: center;
  background-color: #E9ECF4;
}

.scan-barcode-dialog__title {
  font-size: 2rem;
}

.MuiDialog-container .MuiDialogTitle-root + .scan-barcode-dialog__content {
  padding: 5rem 8rem;
}

.scan-barcode-dialog__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scan-barcode-dialog__call-to-scan-wrapper {
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.scan-barcode-dialog__call-to-scan-item:not(:last-of-type) {
  margin-bottom: 4rem;
}

.scan-barcode-dialog__call-to-scan-link {
  cursor: pointer;
}

button.scan-barcode-dialog__call-to-scan-close-button {
  margin-top: 4rem;
  font-size: 1.4rem;
}

.scan-barcode-dialog__searching-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scan-barcode-dialog__searching-item:not(:last-of-type) {
  margin-bottom: 4rem;
}

.scan-barcode-dialog__searching-name {
  font-size: 1.6rem;
}

.scan-barcode-dialog__searching-text {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

div.scan-barcode-dialog__searching-name {
  height: 4rem;
  padding: 1rem 2rem;
  margin-bottom: 4rem;
}

.scan-barcode-dialog__searching-name span {
  font-size: 1.2rem;
}

.scan-barcode-dialog__searching-name-label {
  font-weight: 600;
}

.scan-barcode-dialog__not-found-alert.MuiPaper-root{
  margin-bottom: 0;
  font-size: 1.4rem;
}
