.sheet-operation-status-change-dialog__mark-whole-entity-batch-defective-checkbox,
.sheet-operation-status-change-dialog__defect-reason-text-area-wrap {
    margin-bottom: 2rem;
}

.sheet-operation-status-change-dialog__defective-entities-marking-form-switch,
.sheet-operation-status-change-dialog__text-field-wrap{
    margin-bottom: 3rem;
}

.sheet-operation-status-change-dialog__text-field label,
.sheet-operation-status-change-dialog__text-field input{
    font-size: 1.8rem;
}

.sheet-operation-status-change-dialog__defective-entities-marking-section-title {
    font-weight: bold;
}

#sheet-operation-status-change-dialog__text-field-id-helper-text{
    font-size: 1.2rem;
    position: absolute;
    bottom: -2.2rem;
}

#sheet-operation-status-change-dialog__defect-reason-text-area-id-helper-text {
    margin: 0;
    font-size: 1.2rem;
    position: absolute;
    bottom: -1.2rem;
}

.sheet-operation-status-change-dialog__defective-entities-marking-section-entity-batch-amount,
.sheet-operation-status-change-dialog__defect-reason-text-area-label {
    font-size: 1.35rem;
    color: rgba(0, 0, 0, 0.6);
}
.sheet-operation-status-change-dialog__defect-reason-text-area-label--has-error{
    color: #f44336;
}

.sheet-operation-status-change-dialog__defect-reason-text-area > div{
    display: block;
    font-size: 1.8rem;
    padding: 1.2rem 1.2rem 0.7rem 1.2rem;
    margin-bottom: 1.2rem;
}

#sheet-operation-status-change-dialog__defect-reason-text-area-id{
    line-height: 2rem;
}


.sheet-operation-status-change-dialog__defective-entities-marking-section-entity-batch-amount,
.sheet-operation-status-change-dialog__mark-whole-entity-batch-defective-checkbox span{
    font-size: 1.6rem;
}
