.react-table-wrapper .ReactTable{
    box-shadow: 0 0.1rem 0.5rem 0 rgba(0, 0, 0, 0.2), 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.14), 0 0.3rem 0.1rem -0.2rem rgba(0, 0, 0, 0.12);
}

.react-table-wrapper--no-data .ReactTable .rt-table{
    flex: unset;
    box-shadow: 0 0.2rem 1.5rem 0 rgba(0,0,0,0.15);
}

.react-table-wrapper .rt-noData{
    font-size: 2rem;
    text-align: center;
    background: inherit;
    padding: 0;
    flex: 1 0 auto;
    position: unset;
    transform: unset;
    transition: unset;
    left: unset;
    top: unset;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*
* Более детальные селекторы нужны, чтобы переопределить стили react-table
*/

.react-table-wrapper .ReactTable .react-table-wrapper__table-column-default-header{
    padding: 1rem;
    font-size: 1.6rem;
    white-space: normal;
}

.react-table-wrapper .ReactTable .react-table-wrapper__table-column-default-header:focus{
    outline: none;
}

.react-table-wrapper .ReactTable .react-table-wrapper__table-column-default-header .rt-resizer {
    right: -0.3rem;
    width: 0.6rem;
}

.react-table-wrapper .ReactTable .react-table-wrapper__table-default-head{
    overflow-x: hidden;
    overflow-y: scroll;
}

.react-table-wrapper .ReactTable .react-table-wrapper__table-default-body{
    overflow-x: hidden;
    overflow-y: scroll;
}

.react-table-wrapper .ReactTable .react-table-wrapper__table-default-row-group{
    flex: 0 0 auto;
}

.react-table-wrapper--clickable-rows .ReactTable .react-table-wrapper__table-default-row-group{
    cursor: pointer;
}

.react-table-wrapper--clickable-rows .ReactTable .react-table-wrapper__table-default-row-group:hover{
    background-color: rgba(0, 0, 0, 0.08);
}

.react-table-wrapper--clickable-rows .ReactTable .react-table-wrapper__table-default-row-group:active{
    background-color: rgba(0, 0, 0, 0.1);
}

.react-table-wrapper .ReactTable .react-table-wrapper__table-column-default-cell{
    padding: 1rem;
    font-size: 1.4rem;
    white-space: normal;
    display: flex;
    align-items: center;
    align-content: center;
}

.react-table-wrapper .ReactTable .table-cell-with-center-text-align{
    text-align: center;
}