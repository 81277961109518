.user-editing-screen {
    margin: 3rem auto;
    width: 100rem;
}

.user-editing-screen .card-with-custom-header > .card-with-custom-header__card-content-wrap > .card-with-custom-header__card-content {
    padding: 2.9rem 1rem 1.6rem 1rem;
}

.user-editing-screen .card-with-custom-header__header-wrap {
    height: 6.8rem;
    display: flex;
    align-items: center;
}

.user-editing-screen__card-title {
    margin: 0 8rem 0 2rem;
    font-size: 1.6rem;
}

.user-editing-screen .user-editing-screen__user-roles-select-field,
.user-editing-screen .user-editing-screen__text-field {
    margin-bottom: 3.8rem;
}

.user-editing-screen .user-editing-screen__last-name-field,
.user-editing-screen .user-editing-screen__name-field,
.user-editing-screen .user-editing-screen__patronymic-name-field {
    width: 30rem;
    margin-right: 2.4rem;
}

.user-editing-screen .user-editing-screen__password-field,
.user-editing-screen .user-editing-screen__identity-field {
    width: 46.2rem;
    margin-right: 2.4rem;
}

.user-editing-screen .user-editing-screen__note-field {
    margin-bottom: 2.1rem;
    width: 94.8rem;
}

.user-editing-screen .user-editing-screen__patronymic-name-field,
.user-editing-screen .user-editing-screen__password-field {
    margin-right: 0;
}


.user-editing-screen  .user-editing-screen__text-field label {
    font-size: 1.6rem;
}

.user-editing-screen .user-editing-screen__user-roles-select-field--error,
.user-editing-screen .user-editing-screen__text-field--error {
    margin-bottom: 1.7rem;
}

.user-editing-screen .user-editing-screen__text-field--error.user-editing-screen__identity-field,
.user-editing-screen .user-editing-screen__text-field--error.user-editing-screen__password-field {
    margin-bottom: 0;
}


.user-editing-screen .user-editing-screen__text-field p {
    font-size: 1.1rem;
}

.user-editing-screen .user-editing-screen__text-field-input-wrap {
    font-size: 1.6rem;
}

.user-editing-screen .user-editing-screen__card-actions {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.user-editing-screen .user-editing-screen__submit-button,
.user-editing-screen .user-editing-screen__deactivate-button {
    display: block;
    font-size: 1.2rem;
}

.user-editing-screen .user-editing-screen__return-to-users-list-button {
    color: white;
    font-size: 1.3rem;
    padding: 0.8rem 1rem;
}

.user-editing-screen__return-to-users-list-button-icon{
    font-size: 1.6rem;
}

.user-editing-screen__deactivate-button-tooltip-popper > .user-editing-screen__deactivate-button-tooltip {
    font-size: 1.2rem;
}