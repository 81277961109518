.table-options-button-renderer .table-options-button-renderer__menu-btn {
    width: 2.6rem;
    height: 2.6rem;

    /*Такой стиль назначается в  material-ui */
    background-color: rgba(0, 0, 0, 0.08);
}

.table-options-button-renderer__menu ul div{
    outline: none;
}

.table-options-button-renderer__menu .table-options-button-renderer__menu-item {
    font-size: 1.3rem;
    outline: none;
}

.table-options-button-renderer__menu-item--disabled {
    opacity: 50%;
}

.table-options-button-renderer__menu-item--disabled:hover {
    cursor: default;
}

.table-options-button-renderer__menu-item--active {

}

.table-options-button-renderer__menu-item-tooltip {
    font-size: 1.3rem;
    padding: 0.5rem;
    line-height: 1.4rem;
}