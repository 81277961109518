.sheet-operation-review-dialog .sheet-operation-review-dialog__dialog-paper {
    padding: 0 2rem;
}

/*
* При переходе на версию 5 mui изменил дефолтные максимальные размеры модальников, но для модальника просмотра операции
* более предпочтительный старый размер, т.к. там много информации.
*/
.sheet-operation-review-dialog .sheet-operation-review-dialog__dialog-paper,
.sheet-operation-review-dialog__consume-entities-dialog .consume-entities-dialog__dialog-paper {
    max-width: 128rem;
}

.sheet-operation-review-dialog__header,
.sheet-operation-review-dialog__help-alert-section{
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
}

.sheet-operation-review-dialog__content {
    padding-bottom: 1.4rem;
}

.sheet-operation-review-dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sheet-operation-review-dialog__title {
    font-size: 2rem;
}

.sheet-operation-review-dialog__action-buttons{
    display: flex;
}

.sheet-operation-review-dialog__action-buttons button{
    font-size: 1.4rem;
    margin-right: 0.5rem;
}

.sheet-operation-review-dialog__status-change-buttons > button:last-child{
    margin-right: 0;
}

.sheet-operation-review-dialog__additional-actions-dropdown{
    margin-left: 2rem;
}

.sheet-operation-review-dialog__additional-actions-dropdown-button-content{
    display: flex;
    font-size: 1.4rem;
    align-items: center;
}

.sheet-operation-review-dialog__additional-actions-dropdown-button-content > svg{
    margin-left: 0.2rem;
}

li.sheet-operation-review-dialog__additional-actions-dropdown-menu-item{
    font-size: 1.3rem;
    text-transform: uppercase;
    padding: 1.2rem 1.6rem;
}

.sheet-operation-review-dialog__additional-actions-dropdown-menu-item-tooltip-popper > .sheet-operation-review-dialog__additional-actions-dropdown-menu-item-tooltip {
    font-size: 1.2rem;
}

.sheet-operation-review-dialog__help-alert-section{
    width: 100%;
    text-align: center;
}

.sheet-operation-review-dialog__section-title{
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 3rem;
    display: flex;
    align-items: center;
}

.sheet-operation-review-dialog .simple-summary__operation-status-value > div {
    height: 1.7rem;
    font-size: 1.1rem;
    text-transform: uppercase;
}

.sheet-operation-review-dialog .simple-summary__operation-status-value > div > span {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.sheet-operation-review-dialog .sheet-operation-review-dialog__comment-text-area > div{
    font-size: 1.4rem;
    padding: 1.2rem;
}

.sheet-operation-review-dialog__history-section .ReactTable {
    box-shadow: none;
}

.sheet-operation-review-dialog__additional-title-content.MuiChip-root {
    display: inline-grid;
    font-size: 1.2rem;
    margin-left: 1rem;
    font-weight: normal;
}

.sheet-operation-review-dialog__is-required-mark {
    color: #d32f2f;
    font-size: 1.5rem;
    margin-left: .3rem;
    margin-top: -.9rem;
}

#EQUIPMENT_IS_NOT_AVAILABLE_NOTIFICATION_ID {
    cursor: default;
}