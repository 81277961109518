.collapsible__header {
  display: flex;
  align-items: center;
  margin-left: -1.7rem;
  padding: 1rem 0;
  cursor: pointer;
}

.collapsible__header:hover .collapsible__header-icon {
  background: #ececec;
}

.collapsible__header .collapsible__header-icon {
  margin-right: .2rem;
  border-radius: .2rem;
  transition: background-color .2s, transform .2s;
}

.collapsible__header-icon--closed {
  transform: rotate(-90deg);
}

.collapsible__content {
  padding-bottom: 1.4rem;
  padding-top: .1rem;
}
