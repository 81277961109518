.card-with-nav-header__header{
    display: flex;
    align-items: center;
}

.card-with-nav-header__card-title{
    margin: 0 8rem 0 2rem;
    font-size: 1.6rem;
}

.card-with-nav-header .card-with-nav-header__tabs{
    min-height: unset;
}

.card-with-nav-header__tab-indicator{
    display: none;
}

.card-with-nav-header .card-with-nav-header__tab{
    padding: 1.1rem 1.5rem;
    border-radius: 0.3rem;
    margin-left: 2rem;
    width: unset;
    min-width: unset;
    max-width: unset;
    height: unset;
    min-height: unset;
    max-height: unset;
    font-size: 1.3rem;
    display: flex;
    flex-direction: row;
}

.card-with-nav-header .card-with-nav-header__tab:hover{
    background-color: rgba(0, 0, 0, 0.08);
}

.card-with-nav-header .card-with-nav-header__tab--selected{
    transition: 0.2s background-color 0.1s;
    background-color: rgba(255, 255, 255, 0.2);
}


.card-with-nav-header .card-with-nav-header__tab > svg{
    font-size: 1.5rem;
    margin: 0 0.5rem 0 0;
}