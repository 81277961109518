.new-settings-owner-selects{
    display: flex;
    margin-bottom: 5rem;
}

.new-settings-owner-selects__department-select{
    margin-right: 2rem;
}

.new-settings-owner-select__wrapper {
    flex: 1 0 auto;
}