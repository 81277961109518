/* control-panel */
.simple-table-with-draggable-rows__control-panel-title {
  margin-bottom: 1.2rem;
  line-height: 1.6rem;
  font-size: 1.6rem;
  color: #00000060;
  margin-top: .8rem;
}

.simple-table-with-draggable-rows__control-panel {
  display: flex;
  justify-content: space-between;
}

.simple-table-with-draggable-rows__buttons {
  display: flex;
  align-items: center;
}

.simple-table-with-draggable-rows__buttons button {
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: .165rem .8rem;
}

.simple-table-with-draggable-rows__buttons button .MuiButton-startIcon {
  margin-right: 0;
}

.simple-table-with-draggable-rows__buttons button .MuiButton-startIcon svg {
  font-size: 1.9rem;
}

.simple-table-with-draggable-rows__buttons button + button {
  margin-left: 1rem;
}

/* table-header */

.simple-table-with-draggable-rows__header {
  background-color: #E9ECF4;
  display: flex;
  pointer-events: none;
  padding: 1.5rem 0 1.2rem;
}

.simple-table-with-draggable-rows__header-title {
  color: #00000087;
  line-height: 1.8rem;
  font-size: 1.4rem;
}

.simple-table-with-draggable-rows__header .simple-table-with-draggable-rows__column--icon {
  opacity: 0;
}

/* draggable-container */

.simple-table-with-draggable-rows__draggable-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 54.3rem;
  margin-top: 1rem;
  max-width: 86rem;
  padding-right: 6rem;
  margin-right: -6rem;
  position: relative;
}

.simple-table-with-draggable-rows__draggable-container--is-dragging-over {
  background: #f9f9f9;
}


/* rows */

.simple-table-with-draggable-rows__rows {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 80rem;
  padding-bottom: 4rem;
}

/* row */

.simple-table-with-draggable-rows__draggable-row {
  user-select: none;
  padding: 1.5rem 0 1.2rem 0;
  background: #fff;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.simple-table-with-draggable-rows__draggable-row:hover {
  opacity: .8;
}

.simple-table-with-draggable-rows__draggable-row:active {
  opacity: 1;
}


.simple-table-with-draggable-rows__draggable-row--is-dragging{
  background: #D6D6D6;
  box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.25);
}

/* row-content */
.simple-table-with-draggable-rows__row-content{
  display: flex;
  align-items: center;
  width: 100%;
  color: #606060;
  line-height: 2.4rem;
  font-size: 1.6rem;
}

/* row-item */

.simple-table-with-draggable-rows__column {
  display: flex;
}

.simple-table-with-draggable-rows__column + .simple-table-with-draggable-rows__column {
  padding-left: 1.5rem;
}

/* icon */

.simple-table-with-draggable-rows__column-icon {
  width: 1.8rem;
  height: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.simple-table-with-draggable-rows__column--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.4rem;
}

.simple-table-with-draggable-rows__column-icon:before,
.simple-table-with-draggable-rows__column-icon:after {
  content: '';
  width: 0;
  height: 0;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  border-right: .5rem solid transparent;
  border-left: .5rem solid transparent;
  border-bottom: .5rem solid #5B5B5B60;
}

.simple-table-with-draggable-rows__column-icon:after {
  bottom: 0;
  top: auto;
  transform: translate(-50%, 0) rotate(180deg);
}

.simple-table-with-draggable-rows__column-icon-line {
  height: .2rem;
  width: 100%;
  border-radius: .7rem;
  background: #5B5B5B60;
}

.simple-table-with-draggable-rows__column-icon-line + .simple-table-with-draggable-rows__column-icon-line {
  margin-top: .2rem;
}

