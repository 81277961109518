.sheet-to-finish-dialog__summary.sheet-summary .simple-summary__primary-content-wrapper{
    font-size: 1.6rem;
    text-transform: none;
    margin-bottom: 0;
}

.sheet-to-finish-dialog .sheet-to-finish-dialog__confirm-text--warning {
    background: #fff4e5;
    color: #663c00;
}

.sheet-to-finish-dialog__unprovided-default-sheet-storage-management-app-link,
.sheet-to-finish-dialog__unprovided-default-sheet-providing-additional-info{
    margin-top: 1rem;
}