.sidebar-menu__paper {
    width: 25rem;
    overflow: hidden;
}

.sidebar-menu ul {
    padding: 1.5rem;
}

.sidebar-menu a {
    text-decoration: none;
}

.sidebar-menu .sidebar-menu__item {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 1.5rem;
    padding: 1rem;
    border-radius: .3rem;
}

.sidebar-menu .sidebar-menu__item.sidebar-menu__item--active {
    background-color: transparent;
    color: #2196f3;
}

.sidebar-menu .sidebar-menu__item-icon {
    min-width: 4rem;
}

.sidebar-menu .sidebar-menu__item-icon svg {
    width: 2.3rem;
    height: 2.3rem;
}

.sidebar-menu .sidebar-menu__item-caret-icon {
    height: 1.5rem;
    margin-bottom: 0.3rem;
}

.sidebar-menu__submenu-wrapper ul {
    padding: 0 0 0 1.5rem;
}