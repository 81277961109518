.entity-review-dialog__dialog-bar{
    padding: 2rem;
    color: #fff;
}

.entity-review-dialog__dialog-bar-toolbar {
    justify-content: space-between;
}

.entity-review-dialog__dialog-bar button{
    font-size: 1.5rem;
    line-height: normal;
    padding: 0.8rem 1.6rem;
    flex: 0 0 auto;
}

.entity-review-dialog__dialog-content{
    margin-top: 3rem;
    padding: 0 3rem;
}