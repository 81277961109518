.sheet-operation-note-section__note-text-area > div{
    display: block;
    font-size: 1.4rem;
    padding: 1.2rem 1.2rem 0.7rem 1.2rem;
}

.sheet-operation-note-section .sheet-operation-note-section__note-text-area.sheet-operation-note-section__note-text-area--changed > div > fieldset {
    border-color: #2196f3;
    border-width: 0.2rem;
}

#sheet-operation-note-section__note-text-area-id{
    line-height: 2rem;
}

.sheet-operation-note-section .sheet-operation-note-section__note-textarea-adornment{
    height: unset;
    justify-content: flex-end;
    margin-left: 0;
    margin-top: 0.7rem;
}

.sheet-operation-note-section .sheet-operation-note-section__note-submit-button{
    font-size: 1.2rem;
}

.sheet-operation-note-section .sheet-operation-note-section__note-changed-help-chip > span{
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.sheet-operation-note-section__read-only-note{
    font-size: 1.6rem;
    white-space: pre-wrap;
}

.sheet-operation-note-section .sheet-operation-note-section__read-only-note-is-not-defined-chip{
    font-size: 1.2rem;
    margin: 0.2rem;
    height: 2.5rem;
}
