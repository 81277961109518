.sheet-operation-assignees_section__assignees-autocomplete,
.sheet-operation-assignees_section__assignees-read-only-info{
    width: 90%
}

.sheet-operation-assignees-section .sheet-operation-assignees-section__assignee-chip {
    font-size: 1.2rem;
    margin: 0.2rem;
    height: 2.5rem;
}
