.users-table-screen .users-table-screen__card-with-header {
    margin-top: 3rem;
}

.users-table-screen .users-table-screen__create-new-user-btn {
    font-size: 1.3rem;
    line-height: 1.25;
    color: white;
    padding: 1.1rem 1rem;
}

.users-table-screen .tab-content-wrapper {
    padding: 0 2rem 2rem 2rem;
}

.users-table-screen .ReactTable {
    box-shadow: none;
}