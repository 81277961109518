.paused-sheet-review-content{
    position: relative;
}

.paused-sheet-review-content__paused-sheet-note{
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.paused-sheet-review-content__paused-sheet-note-label{
    font-weight: bold;
}

.paused-sheet-review-content__resume-button{
    text-align: right;
    margin-bottom: 1rem;
}

.paused-sheet-review-content__resume-button button{
    font-size: 1.4rem;
}