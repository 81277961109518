.text-input-filter-component input {
    font-size: 1.2rem;
}

.text-input-filter-component label {
    font-size: 1.4rem;
}

.text-input-filter-component .text-input-filter-component__helper-text {
    font-size: 1.1rem;
}
