.entities-review-table__item-left-side-content{
    max-width: 70%;
}

.entities-review-table__item-right-side-content{
    text-align: right;
    max-width: 30%;
    padding-top: 45px;
}

.entities-review-table__item-additional-info-label-list {
    display: flex;
    position: absolute;
    top: 0.8rem;
    right: 2.8rem;
}

.entities-review-table__item-additional-info-label + .entities-review-table__item-additional-info-label {
    margin-left: .8rem;
}

.entities-review-table__item-additional-info-label-tooltip-content {
    font-size: 1.3rem;
    padding: 0.5rem;
    line-height: 1.4rem;
}
