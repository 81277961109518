.entities-review-table .entities-review-table__list-item{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-clip: padding-box;
    padding: 2rem 2.8rem;
    position: relative;
    align-items: flex-start;
    display: flex;
}

.entities-review-table .entities-review-table__list-item-content {
    display: flex;
    justify-content: space-between;
    flex: 1 0 auto;
    max-width: 100%;
}

.entities-review-table .entities-review-table__list-item:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.entities-review-table__list-item.entities-review-table__list-item--with-checkbox {
    padding-left: 4rem;
}

.entities-review-table__rows-selection-controls {
    display: flex;
    justify-content: space-between;
    padding: 0 2.8rem;
    margin: 1.6rem 0 .8rem;
}

.entities-review-table__rows-selection-controls .MuiTypography-root {
    font-size: 1.4rem;
    line-height: 1;
}

.entities-review-table__rows-selection-controls .MuiCheckbox-root svg {
    width: 2.4rem;
    height: 2.4rem;
}

.entities-review-table__rows-selection-controls .MuiButton-root {
    font-size: 1.4rem;
}

.entities-review-table__left-selection-controls {
    display: flex;
}

.entities-review-table__left-selection-controls .MuiButton-root {
    text-transform: none;
}

.entities-review-table__no-data-content{
    text-align: center;
    font-size: 1.5rem;
}

.entities-review-table__columns-filters-wrap{
    margin-bottom: 1.4rem;
}

.entities-review-table-filters .filters button{
    font-size: 1.4rem;
    padding: 0.8rem 1.6rem;
}

.entities-review-table-filters .filters__chip span{
    font-size: 1.4rem;
}

.entities-review-table__top-table-pagination-with-table-menu-wrap,
.entities-review-table__bottom-table-pagination-wrap{
    padding: 0;
}

.entities-review-table__list-item .entities-review-table__row-select-checkbox {
    padding: 1rem;
    margin-left: -1.1rem;
    margin-top: -.6rem;
}

.entities-review-table__list-item .entities-review-table__row-select-checkbox svg {
    width: 2.4rem;
    height: 2.4rem;
}

.entities-review-table__row-select-checkbox-wrapper {
    display: flex;
    height: auto;
    align-self: stretch;
    align-items: flex-start;
}

.entities-review-table__row-select-checkbox-wrapper + .entities-review-table__list-item-content {
    /* Вычитаем ширину чекбокса, потому что 100% ширины - это ширина родительского контейнера */
    max-width: calc(100% - 3.3rem);
}