.equipment-entity-creation-form .equipment-entity-creation-form__text-field {
    margin-bottom: 4.2rem;
}

.equipment-entity-creation-form .equipment-entity-creation-form__text-field.equipment-entity-creation-form__text-field--error {
    margin-bottom: 2rem;
}

.equipment-entity-creation-form .equipment-entity-creation-form__text-field:last-child {
    margin-bottom: 2.2rem;
}
.equipment-entity-creation-form .equipment-entity-creation-form__text-field:last-child.equipment-entity-creation-form__text-field--error {
    margin-bottom: 0;
}

.equipment-entity-creation-form__text-field label,
.equipment-entity-creation-form .equipment-entity-creation-form__text-field-input-wrap {
    font-size: 1.8rem;
}

.equipment-entity-creation-form .equipment-entity-creation-form__text-field p {
    font-size: 1.2rem;
}