.chip-list__content {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: -.6rem -.75rem 1.8rem;
  overflow: auto;
  max-height: 54rem;
}

.chip-list__content .chip-list__item {
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 1.05rem 1.3rem;
  height: 3.5rem;
  margin: .6rem .75rem;
}

.chip-list__content .chip-list__item--with-delete {
  padding: 1.05rem 4.8rem 1.05rem 1.3rem;
}

.chip-list__content .chip-list__item .MuiChip-deleteIcon {
  padding-right: 1rem;
  margin: 0;
  font-size: 36px;
  position: absolute;
  right: 0;
}

.chip-list__item .MuiChip-label {
  padding: 0;
  line-height: 1.4rem;
}

.chip-list__show-more {
  display: block;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
}

.chip-list__show-more--hidden {
  opacity: 0;
  pointer-events: none;
}