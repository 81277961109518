.all-sheets-to-start__no-sheets-to-start-label {
    font-size: 1.5rem;
    text-align: center;

    /*
    Этот паддинг нужен для выравнивания лейбла отстуствия основного расчета по вертикали на одном уровне с лейблами об
    отсуствии табличных данных на соседних вкладках. Возможно, в последствии будет лучше реализовать тут какое-то другое
    выравнивание (по центру).
    */
    padding-top: 1.4rem;
}

.all-sheets-to-start__date-time-interval-filters{
    margin-bottom: 3rem;
}

.all-sheets-to-start__date-time-interval-filters-label {
    font-weight: bold;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    margin-top: -1.7rem;
}

.group-sheets-start-dialog .group-sheets-start-dialog__title {
    font-size: 2rem;
    padding: 2rem;
    line-height: 2.6rem;
    width: 100%;
    text-align: center;
    background: #E9ECF4;
}

.group-sheets-start-dialog .group-sheets-start-dialog__content {
    padding: 0 4rem 7rem;
}

.group-sheets-start-dialog .group-sheets-start-dialog__description {
    line-height: 2.4rem;
    font-size: 1.6rem;
    text-align: center;
    margin-top: 4.3rem;
    margin-bottom: 1.8rem;
}

.group-sheets-start-dialog__description p {
    margin: 0;
}

.group-sheets-start-dialog__buttons {
    display: flex;
    margin-top: 2.6rem;
}

.group-sheets-start-dialog__buttons button {
    flex-basis: 50%;
    flex-grow: 1;
    font-size: 1.4rem;
}

.group-sheets-start-dialog__buttons .group-sheets-start-dialog__button + .group-sheets-start-dialog__button {
    margin-left: 3rem;
}

.group-sheets-start-dialog__progress-container p {
  text-align: center;
  font-size: 1.4rem;
  margin: 3rem 0;
}

.all-sheets-to-start__selected-sheets-notification {
    text-align: center;
    pointer-events: none;
}

.required-amount-form {
    display: flex;
    align-items: center;
}

.required-amount-form .required-amount-text-field {
    margin-bottom: 0;
}