.defective-sheet-review-content .simple-help-alert__chip {
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
}

.defective-sheet-review-content__sheet_summary {
    margin-bottom: 4.7rem;
}

.defective-sheet-review-content__sheet_summary .simple-info-row__label {
    font-weight: bold;
}

.defective-sheet-review-content__sheet_summary > div > span {
    width: 100%;
}

.transactions-info__inner-wrapper {
    display: flex;
    width: 100%;
}

.transactions-info__simple-summary {
    width: 50%;
    margin-right: 4rem;
}

.transactions-info .simple-info-row__label {
    font-weight: bold;
}
