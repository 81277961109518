.simple-tabs {
    display: flex;
}

.simple-tabs.simple-tabs--vertical {
    flex-direction: row;
}
.simple-tabs.simple-tabs--horizontal {
    flex-direction: column;
}

.simple-tabs--vertical .simple-tabs__tab-content {
    margin: 0 2rem;
    width: 100%
}
.simple-tabs--horizontal .simple-tabs__tab-content {
    margin-top: 2rem;
}

.simple-tabs__tab-label {
    font-size: 1.2rem;
}


