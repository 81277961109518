.simple-help-alert .simple-help-alert__chip {
    font-size: 1.6rem;
    height: unset;
    padding: 0.3rem 0;
    white-space: normal;
    border-radius: 0.4rem;
    margin-left: -0.5rem;
    margin-right: -0.6rem;
}

.simple-help-alert .simple-help-alert__chip > span {
    white-space: normal;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}