.text-form-field {
    display: inline-block;
}

.text-form-field .text-form-field__text-field {
    margin-bottom: 3.8rem;
}

.text-form-field .text-form-field__text-field-input-wrap {
    font-size: 1.6rem;
}

.text-form-field label {
    font-size: 1.6rem;
    z-index: 0;
}

.text-form-field p {
    font-size: 1.1rem;
}

.text-form-field .MuiOutlinedInput-input {
    padding: .9rem 1rem;
    font-size: 1.4rem;
}