.notification-content-with-actions__button {
  font-size: 1.2rem;
  margin-top: 1rem;
  padding: .4rem 1rem;
  background: #1e88e5;
  color: #fff;
  border: none;
  border-radius: .4rem;
  text-transform: uppercase;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.75;
  cursor: pointer;
  transition: opacity .2s;
}

.notification-content-with-actions__button:hover {
  opacity: .8;
}