.simple-item-choose-panel{
    padding: 2rem;
    transition: all 300ms linear;
}

.simple-item-choose-panel--check{
    opacity: 0;
    transform: translate3d(0, -15rem, 0);
}


.simple-item-choose-panel__grid-item-wrap{
    padding: 4rem;
}

.simple-item-choose-panel__item{
    padding: 1.5rem 2.5rem;
    user-select: none;
    cursor: pointer;
    width: 40rem;
}

.simple-item-choose-panel__item--disabled{
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.38);
}

.simple-item-choose-panel__item-content-wrap{
    overflow: hidden;
}

.simple-item-choose-panel__item:active{
    background-color: #2196f3;
}

.simple-item-choose-panel__item--disabled:active{
    background-color: inherit;
}

.simple-item-choose-panel__no-items-text-wrap{
    font-size: 3rem;
    text-align: center;
    padding: 3rem;
}

.simple-item-choose-panel__item-tooltip-popper > .simple-item-choose-panel__item-tooltip{
    font-size: 1.2rem;
}