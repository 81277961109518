.card-with-custom-header{
    display: flex;
    position: relative;
    word-wrap: break-word;
    flex-direction: column;
}

/*
* переопределяем стили material-ui
*/
.card-with-custom-header.card-with-custom-header--no-overflow{
    overflow: visible;
}

.card-with-custom-header__header-wrap{
    padding: 1.5rem;
    margin: -3rem 1.5rem 0 1.5rem;
    border-radius: 0.3rem;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}

.card-with-custom-header > .card-with-custom-header__card-content-wrap > .card-with-custom-header__card-content{
    padding: 4rem 2.4rem;
}

.card-with-custom-header__actions-content-and-menu-wrap{
    display: flex;
}

.card-with-custom-header__header-wrap .card-with-custom-header__menu-icon{
    padding: 0.7rem;
}

.card-with-custom-header__menu-icon svg{
    font-size: 2.4rem;
}

.card-with-custom-header__card-menu .card-with-custom-header__card-menu-item{
    font-size: 1.3rem;
    text-transform: uppercase;
    padding: 1.2rem 1.6rem;
}