.nested-menu > ul {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.nested-menu .nested-menu__option:hover > .nested-menu__submenu {
  opacity: 1;
  visibility: visible;
  z-index: 20;
}

.nested-menu .nested-menu__option:hover {
  background-color: #eee;
  color: black;
}

.nested-menu .nested-menu__submenu {
  visibility: hidden;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: -0.2rem;
  transform: translateX(-100%);
  transition: 100ms;
  transition-delay: 100ms;
}

.nested-menu__option {
  display: flex;
  position: relative;
}

.nested-menu__option > div {
  display: flex;
  font-size: 1.3rem;
  cursor: default;
}

.nested-menu__option.nested-menu__option--without-submenu > div {
  padding-left: 1.8rem;
  cursor: pointer;
}

.nested-menu__option--active {
  background-color: #666;
  color: white;
}

.nested-menu__option--disabled {
  opacity: 0.6;
}

.nested-menu__option-sub-menu-caret-icon-wrapper {
  display: flex;
  align-items: center;
  width: 1.8rem;
  padding-left: 0.1rem;
}

.nested-menu__option-sub-menu-caret-icon-wrapper .nested-menu__option-sub-menu-caret-icon {
  font-size: 1.8rem;
}

.nested-menu__option-content-wrapper {
  display: flex;
  align-items: center;
}

.nested-menu__option-icon-wrapper {
  display: flex;
  align-items: center;
  width: 1.8rem;
  margin-right: 0.6rem;
}

.nested-menu__option-icon-wrapper .nested-menu__option-icon {
  font-size: 1.8rem;
  color: #666;
}

.nested-menu__option-title {

}