.worker-app-view-mode-choosing-screen,
.worker-app-view-mode-choosing-screen .simple-item-choose-panel,
.worker-app-view-mode-choosing-screen .simple-item-choose-panel__grid {
    height: 100%;
}

.view-mode-item-content__card-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.view-mode-item-content__card-description {
    font-style: italic;
}
