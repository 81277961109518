.sheet-to-start-review-content{
    position: relative;
}

.sheet-to-start-review-content__start-button{
    text-align: right;
    margin-bottom: 1rem;
}

.sheet-to-start-review-content__start-button button{
    font-size: 1.4rem;
}