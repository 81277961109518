.pause-sheet-dialog___pause-sheet-reason-label{
    margin-bottom: 0.5rem;
}

.pause-sheet-dialog__pause-sheet-reason-textarea > div{
    font-size: 1.6rem;
    padding: 1.2rem;
}

#pause-sheet-dialog__pause-sheet-reason-textarea-id{
    line-height: 2rem;
}