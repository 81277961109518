.sheet-operation-settings__checkbox .MuiSvgIcon-root {
  width: 2.3rem;
  height: 2.3rem;
}
.sheet-operation-settings__checkbox .MuiTypography-root {
  font-size: 1.6rem;
}

.sheet-operation-settings__form.MuiFormControl-root {
  margin-top: 2rem;
}

.sheet-operation-settings__tooltip-popper > .sheet-operation-settings__tooltip{
  font-size: 1.2rem;
}