.date-time-interval-form__date-time-pickers-section-wrap {
    display: flex;
    align-items: flex-end;
}

.date-time-interval-form .date-time-interval-form__date-time-picker-wrap {
    margin-right: 1rem;
}

.date-time-interval-form .date-time-interval-form__date-time-picker-label {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.1rem;
}

.date-time-interval-form .date-time-interval-form__date-time-picker input {
    height: 1.7rem;
    font-size: 1.4rem;
    padding: 1rem;
    cursor: pointer;
}

.date-time-interval-form .date-time-interval-form__button{
    height: 3.7rem;
}

.date-time-interval-form .date-time-interval-form__button{
    margin-left: 1rem;
    font-size: 1.4rem;
    box-shadow: none;
}

.date-time-interval-form__button-tooltip-popper > .date-time-interval-form__button-tooltip {
    font-size: 1.2rem;
}


/*
* Далее начинается переопределение стилей внутри модальника дейтпикера. В данный момент развития, mui
* предполагает, что удобней всего использовать их систему стилизации с применением emotion. Поэтому,
* глобально у всех компонентов не всегда очевидно, как просто назначить отдельные css классы чтобы добавить
* просто для них стили. А здесь ещё дополнительно мы используем пикеры из пакета mui\lab, где компоненты
* находятся на ранних стадиях разработки и пока они их не хотят переносить в основной пакет - и для таких
* пакетов, видимо, этот момент со стилизацией проработан ещё хуже, потому что единственное упоминание о
* классах - это пропс className у пикера, и даже он, судя по всему, просто игнорируется, потому что в разметке
* класс не появляется.
* Поэтому, далее идут весьма специфичные селекторы для стилизации модальника дейтпикера: где были какие-то
* отличающиеся классы, там пытались их использовать, где не было - там идёт строгая последовательность
* элементов разметки + использование общих css классов mui, но внутри наших частных классов, чтобы селектились
* только эти классы внутри модальника пикера
*
* В целом, стоит отметить, что, наверно, у нас исторически немного сделано неправильно со стилями при испольховании
* mui, поэтому в определенных, особенно, кастомных моментах мы немного мучаемся. Поэтому в будущем, возможно,
* стоит подойти к этому вопросу комплексно и скорректировать нашу логику работы со стилями в mui глобально.
*/

/*
* Нет программной возможности скрыть иконку перехода в ручной режим ввода даты, а нам в данный момент это не нужно,
* поэтому скрываем при помощи css
*/
.date-time-interval-form__modal-container .PrivateDateTimePickerToolbar-penIcon {
    display: none;
}

.date-time-interval-form__modal-container .PrivatePickersToolbar-dateTitleContainer span.MuiTypography-subtitle1 {
    font-size: 1.6rem;
}

.date-time-interval-form__modal-container .PrivatePickersToolbar-dateTitleContainer span.MuiTypography-h4 {
    font-size: 3.4rem;
}

.date-time-interval-form__modal-container .PrivatePickersToolbar-dateTitleContainer span.MuiTypography-h3 {
    font-size: 4.8rem;
}

.date-time-interval-form__modal-container .MuiCalendarPicker-viewTransitionContainer .MuiTypography-caption {
    font-size: 1.2rem;
}

.date-time-interval-form__modal-container .PrivatePickersFadeTransitionGroup-root,
.date-time-interval-form__modal-container .MuiDialogContent-root > div > div:last-child span{
    font-size: 1.6rem;
}

.date-time-interval-form__modal-container .MuiPickersDay-dayWithMargin {
    font-size: 1.4rem;
}

.date-time-interval-form__modal-container .MuiDialogActions-root button {
    font-size: 1.4rem;
}

.date-time-interval-form__modal-container svg {
    width: 2.4rem;
    height: 2.4rem;
    font-size: 2.4rem;
}

.date-time-interval-form__modal-container .PrivatePickersYear-root > button{
    font-size: 1.6rem;
}

.date-time-interval-form__modal-container .MuiDialogContent-root .PrivatePickersToolbar-root,
.date-time-interval-form__modal-container .MuiDialogContent-root .MuiTabs-root{
    background-color: #2196f3;
}

.date-time-interval-form__modal-container .PrivatePickersToolbar-dateTitleContainer span.MuiTypography-subtitle1,
.date-time-interval-form__modal-container .PrivatePickersToolbar-dateTitleContainer span.MuiTypography-h4,
.date-time-interval-form__modal-container .PrivatePickersToolbar-dateTitleContainer span.MuiTypography-h3,
.date-time-interval-form__modal-container .MuiCalendarPicker-viewTransitionContainer .MuiTypography-caption,
.date-time-interval-form__modal-container .MuiDialogContent-root .MuiTabs-root button{
    color: rgba(255, 255, 255, 0.54);
}

.date-time-interval-form__modal-container .MuiDialogContent-root .PrivatePickersToolbar-root .Mui-selected,
.date-time-interval-form__modal-container .MuiDialogContent-root .MuiTabs-root .Mui-selected{
    color: #fff;
}

.date-time-interval-form__modal-container .MuiDialogContent-root .MuiTabs-root .MuiTabs-indicator{
    background-color: #d32f2f;
}

