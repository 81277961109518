.simple-confirm-dialog .simple-confirm-dialog__title {
    padding: 2.4rem 2.4rem 1.8rem 2.4rem;
    margin-bottom: 1.8rem;
}

.simple-confirm-dialog__title div {
    font-size: 2rem;
    border-bottom: 0.1rem solid grey;
}

.simple-confirm-dialog__confirm-text {
    font-size: 1.6rem;
}

.simple-confirm-dialog .simple-confirm-dialog__confirm-text > .simple-confirm-dialog__confirm-text-chip {
    font-size: 1.6rem;
    height: unset;
    padding: 0.3rem 0;
    white-space: normal;
    border-radius: 0.4rem;
    margin-left: -0.5rem;
    margin-right: -0.6rem;
}

.simple-confirm-dialog .simple-confirm-dialog__confirm-text > .simple-confirm-dialog__confirm-text-chip > span {
    white-space: normal;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.simple-confirm-dialog .simple-confirm-dialog__content {
    padding-bottom: 3rem;
    font-size: 1.6rem;
}

.simple-confirm-dialog .simple-confirm-dialog__content--with-additional-component .simple-confirm-dialog__confirm-text {
    margin-bottom: 3rem;
}

.simple-confirm-dialog .simple-confirm-dialog__buttons-panel {
  margin: 0 1.5rem 1rem 1.5rem;
  padding: 0;
}

.simple-confirm-dialog__buttons-panel button {
    font-size: 1.5rem;
    margin: 0;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
}

.simple-confirm-dialog .simple-confirm-dialog__buttons-panel .simple-confirm-dialog__cancel-button {
    color: rgb(245, 0, 87);
    background-color: rgba(245, 0, 87, 0.11);
    margin-right: auto;
}

.simple-confirm-dialog .simple-confirm-dialog__buttons-panel .simple-confirm-dialog__confirm-button{
    color: rgba(33, 150, 243, 1);
    background-color: rgba(33, 150, 243, 0.08);
}

.simple-confirm-dialog .simple-confirm-dialog__buttons-panel .simple-confirm-dialog__confirm-button--default{
    background-color: rgba(0, 0, 0, 0.08);
}

.simple-confirm-dialog .simple-confirm-dialog__buttons-panel .simple-confirm-dialog__confirm-button--disabled {
    color: rgba(33, 150, 243, 0.5);
}