.sheet-in-production-review-content{
    position: relative;
}

.sheet-in-production-review-content__finish-button{
    text-align: right;
    margin-bottom: 1rem;
}

.sheet-in-production-review-content__finish-button button{
    font-size: 1.4rem;
}