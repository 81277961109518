.app-type-top-bar-user-menu__menu-icon-button-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.app-type-top-bar-user-menu__menu-icon-button svg {
    color: white;
    font-size: 2.4rem;
}

.app-type-top-bar-user-menu__action-button-block {
    display: flex;
    justify-content: space-around;
}

.app-type-top-bar-user-menu__menu ul {
    padding: 0;
    min-width: 20rem;
}

.app-type-top-bar-user-menu__card-header {
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.app-type-top-bar-user-menu__card-header .app-type-top-bar-user-menu__avatar svg {
    font-size: 3.8rem;
}

.app-type-top-bar-user-menu__menu div.app-type-top-bar-user-menu__card-content {
    padding-bottom: 0;
    padding-top: 0;
}

.app-type-top-bar-user-menu__card-content .app-type-top-bar-user-menu__card-content-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
    font-size: 1.4rem;
}

.app-type-top-bar-user-menu__card-content label {
    font-weight: bold;
}

div.app-type-top-bar-user-menu__action-button-block {
    padding: 2rem;
}

.app-type-top-bar-user-menu__action-button-block .app-type-top-bar-user-menu__action-button {
    font-size: 1.2rem;
}