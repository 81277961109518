.sheets__filters{
    margin-bottom: 1.4rem;
}

.sheets__filters button{
    padding: 0.8rem 1.6rem;
}

.sheets .entities-review-table .simple-summary__secondary-content-wrapper{
    color: rgba(0, 0, 0, 0.54);
}

.entity-review-dialog__dialog-bar .sheet-review-dialog__bar-content {
    overflow: hidden;
    white-space: nowrap;
    margin-right: 1rem;
}