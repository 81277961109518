.simple-info-row {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.simple-info-row__label{
    margin-right: 1rem;
}

.simple-info-row__tooltip-popper .simple-info-row__tooltip {
    font-size: 1.3rem;
}
