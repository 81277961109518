.login-screen{
    height: 100%;
}

.login-screen__form-card-wrapper{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.login-screen__form-card{
    min-width: 40rem;
}

.login-screen__form-card-header{
    font-size: 1.8rem;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
}

.login-screen__form-field-wrap{
    margin-bottom: 3rem;
}

.login-screen__form-field-wrap:last-child{
    margin-bottom: 1rem;
}

.login-screen__form-field .login-screen__form-field-input-wrap {
    font-size: 1.4rem;
}

.login-screen__form-field-input-wrap input{
    color: #495057;
}

.login-screen__form-field > label {
    font-size: 1.4rem;
    color: #495057;
}

.login-screen__form-field .login-screen__form-field-icon {
    color: #495057;
    width: 2.4rem;
    height: 2.4rem;
    font-size: 2.4rem;
}

.login-screen__form-submit-button-wrap{
    display: flex;
    justify-content: center;
}

.login-screen__form-submit-button-wrap > .login-screen__form-submit-button{
    font-size: 1.8rem;
    text-transform: uppercase;
    padding: 1.2rem 3.6rem;
    margin-bottom: 1rem;
}

.login-screen__form-card.card-with-custom-header > .card-with-custom-header__card-content-wrap > .card-with-custom-header__card-content{
    padding: 4rem 3rem 0 3rem;
}

.login-screen__top-panel {
    position: absolute;
    right: 0;
}

.login-screen__top-panel .reload-icon-button {
    margin-top: 1rem;
    margin-right: 2.4rem;
}