.tasks-table-filters .filters{
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.12);
    padding-bottom: 0.4rem;
}

.tasks-table-filters .tasks-table-filters__paper {
    background-color: inherit;
}

.tasks-table-filters .filters__chip {
    margin: 0.2rem 0.4rem;
}

.tasks-table-filters .simple-tabs .simple-tabs__tabs {
    width: 30rem;
    height: 35rem;
    border-right: 1px solid grey;
}

.tasks-table-filters .simple-tabs .simple-tabs__tabs .simple-tabs__tab-label {
    font-size: 1.4rem;
    height: 7rem;
    min-height: 7rem;
}

.tasks-table-filters .simple-tabs .simple-tabs__tabs .simple-tabs__tabs-indicator{
    display: none;
}

.tasks-table-filters .simple-tabs .simple-tabs__tabs .simple-tabs__tab-selected {
    background-color: rgba(0,0,0,0.08)
}


.tasks-table-filters .tasks-table-filters__filters-amount-chip {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    position: absolute;
    top: 2.2rem;
    left: 2.2rem;
}

.tasks-table-filters .tasks-table-filters__filters-amount-chip span {
    padding: 0;
    font-size: 1.2rem;
}

.tasks-table-filters__tab-content {
    padding: 2rem;
}

.tasks-table-filters__search-suggestions {
    margin-top: 8rem;
    overflow: hidden;
}

.tasks-table-filters__search-suggestions-chip span {
    font-size: 1.2rem;
}

.tasks-table-filters .tasks-table-filters__search-suggestions-chip {
    margin-bottom: 1rem;
}

.tasks-table-filters__search-suggestions-chip:not(:last-child) {
    margin-right: 1rem;
}
