.sheet-operation-feature-scope-form__form {
    display: flex;
    align-items: flex-end;
}

.sheet-operation-feature-scope-form__autocomplete-wrapper {
    flex: 1;
}

.sheet-operation-feature-scope-form__autocomplete-wrapper + .sheet-operation-feature-scope-form__autocomplete-wrapper {
    margin-left: 1rem;
}

.sheet-operation-feature-scope-form .sheet-operation-feature-scope-form__submit-btn {
    font-size: 1.2rem;
    margin-left: 1rem;
}

.sheet-operation-feature-scope-form__tooltip-popper .sheet-operation-feature-scope-form__tooltip {
    font-size: 1.3rem;
}