.tasks-table-columns-settings-group--is-creating {
    max-width: 83rem;
    padding-right: 3rem;
    margin-right: -3rem;
}

.tasks-table-columns-settings-group--is-creating .simple-table-with-draggable-rows__rows {
    max-width: 73.6rem;
}

.eq-class-in-dep-tasks__submit-buttons {
    display: flex;
    align-items: center;
    margin-left: 2rem;
}