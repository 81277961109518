.global-app-spinner{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200000;
}

.global-app-spinner__shadow{
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.1;
}

.global-app-spinner__loader{
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -4rem;
    margin-left: -4rem;
    opacity: 0.5;
}