.eq-class-in-dep-tasks-control-panel__create-new-settings-buttons {
    margin-bottom: 4em;
}

.eq-class-in-dep-tasks-control-panel__create-new-settings-buttons button{
    margin-right: 2rem;
    line-height: 2.4rem;
    font-size: 1.2rem;
}

.eq-class-in-dep-tasks-control-panel .eq-class-in-dep-tasks-control-panel__create-new-settings-buttons button svg {
    font-size: 1.9rem;
}

.eq-class-in-dep-tasks-control-panel__settings-select-with-controls-wrap{
    margin-bottom: 3rem;
}

.eq-class-in-dep-tasks-control-panel__settings-select{
    max-width: 100%;
    margin-top: 1.2rem;
    font-size: 1.4rem;
}

.eq-class-in-dep-tasks-control-panel__settings-select [class*="singleValue"] {
    white-space: pre-wrap;
}