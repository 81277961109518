.checkbox-filters-panel{
  margin-bottom: 2rem;
}

.checkbox-filters-panel__checkbox-label {
  font-size: 1.2rem;
  text-transform: uppercase;
}

.checkbox-filters-panel__checkbox {
  padding-right: 0.4rem;
}

.checkbox-filters-panel__checkbox svg {
  width: 2.4rem;
  height: 2.4rem;
}

/*
* Для инпута чекбокса материал уи устанавливает z-index 1, тогда как для его лейбла ничего подобного нет. Это приводит
* иногда к достаточно странному поведению, что чекбокс бывает выше каких-то элементов, а его лейбл нет, сбрасываем
* этот стиль, т.к. нам такое не нужно
*/
.checkbox-filters-panel__checkbox input {
  z-index: 0;
}
