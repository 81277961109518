.accordion .MuiPaper-root {
  background-color: inherit;
}

.accordion .MuiAccordionSummary-content {
  margin: 3rem 0;
  /*
  для работы text-overflow: ellipsis; (props "noWrap" для Typography) ограничиваем ширину родителя и задаём display: block
  */
  max-width: 100%;
  display: block;
}

.accordion .accordion__summary {
  padding: 0;
}

.accordion .accordion__details {
  padding: 0;
}


.accordion .accordion__summary-wrapper {
  display: flex;
  align-items: center;
}

.accordion .accordion__summary-text {
  font-size: 2rem;
  font-weight: 400;
  margin-left: 1rem;
}


.accordion .accordion__summary-wrapper--open .accordion__summary-text {
  font-weight: 600;
}

.accordion .accordion__details {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.accordion__summary:hover .accordion__summary-text,
.accordion__summary:hover .accordion__expand-icon {
  color: #2198F3;
}

.accordion .accordion__expand-icon {
  font-size: 2.6rem;
  margin-left: .8rem;
  transition: transform .2s linear;
}

.accordion .accordion__expand-icon--open {
  transform: rotate(-180deg);
}