.sheet-operation-equipment-section .sheet-operation-equipment-section__equipment-chip {
    font-size: 1.2rem;
    margin: 0.2rem;
    height: 2.5rem;
}

.sheet-operation-equipment-section__autocomplete-and-creation-form-wrapper {
    display: flex;
}

.sheet-operation-equipment-section__autocomplete {
    width: 90%;
    margin-right: 1rem;
}

.sheet-operation-equipment-section__create-equipment-btn .MuiIconButton-root {
    padding: 1.15rem;
}
