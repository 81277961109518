.app-choosing-screen {
    height: 100%;
    position: relative;
    transition: all 400ms linear;
}

.app-choosing-screen__top-panel {
    position: absolute;
    right: 0;
}

.app-choosing-screen__top-panel button:last-child {
    margin-top: 1rem;
    margin-right: 2.4rem;
}

.app-choosing-screen__top-panel .reload-icon-button  {
    margin-top: 1rem;
}

.app-choosing-screen__top-panel .app-choosing-screen__top-panel-admin-app-enter-button svg {
    width: 2.4rem;
    height: 2.4rem;
}

.app-choosing-screen > .simple-item-choose-panel,
.app-choosing-screen > .simple-item-choose-panel > .simple-item-choose-panel__grid{
    height: 100%;
}

.app-choosing-screen .simple-item-choose-panel .simple-item-choose-panel__item{
    width: 44rem;
}

.app-choosing-screen--items-hidden{
    opacity: 0;
    transform: translate3d(0, -10rem, 0);
}

.app-choosing-screen__app-card-title{
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.app-choosing-screen__app-card-title-label{
    margin-right: 1rem;
}

.app-choosing-screen__app-card-title-value{
    font-weight: bold;
}

.app-choosing-screen__app-card-description{
    font-style: italic;
}