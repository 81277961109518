.orders-ready-to-complete-review-content__complete-order-button{
    text-align: right;
    margin-bottom: 1rem;
}

.orders-ready-to-complete-review-content__complete-order-button button{
    font-size: 1.4rem;
}

.simple-confirm-dialog__confirm-text-chip.orders-ready-to-complete-review-content__confirm-text--warning {
    background: #fff4e5;
    color: #663c00;
}