.sheet-to-start-params-form-dialog__summary.sheet-summary .simple-summary__secondary-content-wrapper{
    color: inherit;
}

.sheet-to-start-params-form-dialog__summary.sheet-summary .simple-summary__primary-content-wrapper{
    font-size: 1.6rem;
    text-transform: none;
    margin-bottom: 0;
}

.sheet-to-start-params-form-dialog__content .sheet-to-start-params-form-dialog__text-field{
    margin: 3rem 0 0 0;
}

.sheet-to-start-params-form-dialog__text-field input,
.sheet-to-start-params-form-dialog__text-field label{
    font-size: 1.8rem;
}

.sheet-to-start-params-form-dialog__text-field p{
    font-size: 1.2rem;
}